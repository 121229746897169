.standard-button {
    background-color: #9755EA !important;
    color: white;
    font-weight: bold;
    width: 100%;
}

.standard-button:hover {
    background-color: #6729BA !important;
}

.add-btn:hover {
    background-color: #3d9e51 !important;
}